import React, {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import text_styles from 'shared/styles/text_styles.module.scss';
import Button from 'shared/components/Button.js';
import TextArea from 'shared/components/TextArea.js';
import Input from 'shared/components/Input';
import SeparatorLine from 'shared/components/SeparatorLine';
import {formatPrice, formatShortId} from 'shared/utils.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import PriceInput from 'shared/components/PriceInput.js';
import {setFormErrors} from 'shared/effects.js';
import {useNotify} from 'shared/NotifyProvider.js';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import classNames from 'classnames';

import {alert, handleError, confirmClose} from '../../effects.js';
import {
  initiateCreditTransfer,
  requestCloseCashDeposit,
} from '../../actions.js';

import styles from './InitiateCreditTransferDialog.module.scss';

export default function InitiateCreditTransferDialog({onHide, ...props}) {
  const [visible, setVisible] = useState(true);
  const {deposit_id} = useParams();
  const [deposit, setDeposit] = useState(null);
  const {notify} = useNotify();

  useEffect(() => {
    RPC('getCashDeposit', {id: deposit_id})
      .then(setDeposit)
      .catch((err) => {
        handleError(err);
      });
  }, [deposit_id]);

  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
  } = useForm({mode: 'onChange'});

  const release_deposit = watch('release_deposit');

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, deposit, setError, setVisible, notify}),
  );

  if (!deposit) return null;

  const footer = (
    <div className={styles.footer}>
      <div className={text_styles.caption_left}>
        Momo-ID: {formatShortId(deposit.short_id)}
      </div>
      <Button
        title="Transaktion übermitteln"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  return (
    <Dialog
      title="Auszahlung"
      show={visible}
      footer={footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div className={styles.row}>
        <Input
          value={calculateDisplayName(deposit)}
          label="Mieter"
          readOnly
          inputClassName={styles.display_name}
          copyLabel="Mieter"
        />
        <Input
          value={`${deposit.street_name} ${deposit.street_number}, ${deposit.postal_code} ${deposit.region}`}
          label="Adresse"
          readOnly
          inputClassName={styles.address}
        />
      </div>
      <div className={styles.row}>
        <Input
          value={formatPrice(deposit.deposit_amount_cents)}
          label="Kaution"
          readOnly
        />
        <Input
          value={formatPrice(deposit?.account_balance_cents || 0)}
          label="Saldo inkl. geplanter Umsätze"
          readOnly
        />
      </div>
      <SeparatorLine />
      <div className={styles.row}>
        <Input
          label="Empfänger Kontoname"
          {...register('counterparty_name')}
          error={errors.counterparty_name?.message}
        />
      </div>
      <div className={styles.row}>
        <Input
          placeholder="z. B. DE45 123…"
          label="Empfänger IBAN"
          {...register('counterparty_iban')}
          error={errors.counterparty_iban?.message}
        />
      </div>
      <SeparatorLine />
      <Controller
        control={control}
        name="release_deposit"
        defaultValue={false}
        render={({field: {value, onChange, name}}) => {
          return (
            <Checkbox
              id={name}
              name={name}
              textStyle={text_styles.body2_left}
              value={value}
              onChange={onChange}
              error={errors[name]?.message}
              label={'Kaution auflösen (Saldo zuzüglich offener Zinsen)'}
            />
          );
        }}
      />

      <div className={styles.row}>
        {release_deposit ? (
          <div className={styles.account_balance_container}>
            <div className={classNames(text_styles.caption_left, styles.label)}>
              Betrag (€)
            </div>
            <p className={text_styles.body2_left}>
              <span className={text_styles.body2_bold}>
                {formatPrice(
                  deposit?.account_balance_cents + deposit.unpaid_interest,
                )}
              </span>{' '}
              inkl. {formatPrice(deposit.unpaid_interest)} Zinsen aus dem
              laufenden Quartal
            </p>
          </div>
        ) : (
          <Controller
            control={control}
            name="transfer_amount_cents"
            render={({field: {onChange, value, name}}) => (
              <PriceInput
                label="Betrag (€)"
                value={value}
                onChange={onChange}
                error={errors[name]?.message}
                readOnly={release_deposit}
              />
            )}
          />
        )}
      </div>
      <div className={styles.row}>
        <TextArea
          label="Verwendungszweck"
          maxLength="140"
          {...register('remittance_information')}
          error={errors.remittance_information?.message}
        />
      </div>
    </Dialog>
  );
}

async function proceed({fields, deposit, setError, setVisible, notify}) {
  try {
    await (fields.release_deposit
      ? requestCloseCashDeposit({
          deposit_id: deposit.id,
          counterparty_name: fields.counterparty_name,
          counterparty_iban: fields.counterparty_iban,
          remittance_information: fields.remittance_information,
        })
      : initiateCreditTransfer({
          deposit_id: deposit.id,
          counterparty_name: fields.counterparty_name,
          counterparty_iban: fields.counterparty_iban,
          transfer_amount_cents: fields.transfer_amount_cents,
          remittance_information: fields.remittance_information,
        }));
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (Array.isArray(err.data) && err.data.length > 0) {
        setFormErrors({
          setError,
          errors: err.data,
        });
        return;
      }
      if (err.message) {
        alert({title: err.message});
        return;
      }
    }
    handleError(err);
    return;
  }

  notify({text: 'Transaktion übermittelt'});
  setVisible(false);
}

function calculateDisplayName(deposit) {
  if (deposit?.tenant_2_exists) {
    return `${deposit.tenant_1_display_name} & ${deposit.tenant_2_display_name}`;
  }

  return deposit.tenant_1_display_name;
}
