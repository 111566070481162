import React from 'react';
import {useTable} from 'react-table';
import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';
import {formatDate, formatPrice, openExternal} from 'shared/utils.js';
import translateSubStatus from 'shared/ui/translateSubStatus.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';
import {
  DEPOSIT_SUBSTATUS,
  DEPOSIT_TYPES,
  MANAGER_PATHS,
  TAX_SUMMARY_YEAR,
  TYPES,
} from 'shared/constants.js';
import {RPC} from 'shared/api.js';
import {useHistory} from 'react-router-dom';
import {useNotify} from 'shared/NotifyProvider.js';

import {ReactComponent as MoreVerticalSvg} from '../../assets/more_vertical.svg';
import {
  isClaimAllowed,
  isDeleteAllowed,
  isReleaseAllowed,
} from '../../lib/utils.js';
import TableHeaderButton from '../../components/table_header_button/TableHeaderButton.js';
import {handleError} from '../../effects.js';

import styles from './DepositsTable.module.scss';

export default function DepositsTable({
  deposit_guarantees,
  onDelete,
  onRelease,
  onClaim,
  onOpen,
  orderBy,
  sort_column,
  sort_direction,
}) {
  const {getTableProps, headerGroups, getTableBodyProps, rows, prepareRow} =
    useTable({columns, data: deposit_guarantees});

  return (
    <table className={styles.table} {...getTableProps()}>
      <thead className={styles.thead}>
        {headerGroups.map((headerGroup, index) => (
          <tr key={index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                key={idx}
                className={classNames(styles.th, styles[column.id])}
                {...column.getHeaderProps()}>
                {column.sort ? (
                  <TableHeaderButton
                    orderBy={orderBy}
                    column={column}
                    sort_column={sort_column}
                    sort_direction={sort_direction}
                  />
                ) : (
                  <div className={styles.header}>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row);
          return (
            <DepositRow
              row={row}
              key={idx}
              onDelete={onDelete}
              onRelease={onRelease}
              onClaim={onClaim}
              onOpen={onOpen}
            />
          );
        })}
      </tbody>
    </table>
  );
}

function DepositGuaranteeMenu({deposit, onDelete, onRelease, onClaim}) {
  const {notify} = useNotify();

  return (
    <Dropdown>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item
          as="button"
          className={!isDeleteAllowed(deposit) && styles.disabled}
          onClick={() => onDelete({deposit_guarantee: deposit, notify})}>
          Mieter entfernen
        </Dropdown.Item>
        {!deposit.released_at && (
          <>
            <Dropdown.Item
              as="button"
              className={!isReleaseAllowed(deposit) && styles.disabled}
              onClick={() => onRelease(deposit)}>
              Kaution freigeben (Checkliste)
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className={!isClaimAllowed(deposit) && styles.disabled}
              onClick={() => onClaim(deposit)}>
              Kaution einfordern
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
function CashDepositMenu({deposit}) {
  const history = useHistory();
  return (
    <Dropdown>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item
          as="button"
          onClick={() =>
            history.push(
              `${MANAGER_PATHS.InitiateCreditTransferDialog}/${deposit.id}`,
            )
          }>
          Auszahlung
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={() => openTaxSummary({deposit_id: deposit.id})}>
          Einzelaufstellung
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={() =>
            history.push(
              `${MANAGER_PATHS.CashDepositTransactionsDialog}/${deposit.id}`,
            )
          }>
          Transaktionen
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DepositRow({row, onDelete, onRelease, onClaim, onOpen}) {
  return (
    <tr className={styles.tr} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === 'actions') {
          if (row.original.deposit_type === DEPOSIT_TYPES.cash_deposit) {
            return (
              <td {...cell.getCellProps()}>
                <CashDepositMenu deposit={row.original} />
              </td>
            );
          }
          return (
            <td {...cell.getCellProps()}>
              <DepositGuaranteeMenu
                deposit={row.original}
                {...{onDelete, onRelease, onClaim}}
              />
            </td>
          );
        }
        return (
          // getCellProps() has a key
          <td
            {...cell.getCellProps()}
            className={classNames(styles.pointer, styles[cell.column.id])}
            onClick={() => onOpen(row.original)}>
            <div
              className={classNames(
                text_styles[cell.column.text_styles],
                styles[`${cell.column.id}_cell`],
                styles.cell,
              )}>
              <span>{cell.render('Cell')}</span>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

function Tenant({tenant_display_name, type, number_of_tenants}) {
  if (type === TYPES.individual && number_of_tenants > 1) {
    return (
      <div className={styles.tenants_names}>
        <span
          className={classNames(
            text_styles.body1_bold_left,
            styles.tenant_display_name,
          )}>
          {tenant_display_name}
        </span>
        <span className={styles.plus_one}> +1</span>
      </div>
    );
  }
  return <p className={text_styles.body1_bold_left}>{tenant_display_name}</p>;
}

export const columns = [
  {
    id: 'name',
    Header: 'Mieteinheit',
    accessor: ({tenant_display_name, given_reference, number_of_tenants}) => {
      return (
        <div>
          <Tenant
            tenant_display_name={tenant_display_name}
            number_of_tenants={number_of_tenants}
          />
          <p className={text_styles.body1_italic}>{given_reference || '–'}</p>
        </div>
      );
    },
    text_styles: 'body1_bold_left',
    sort: 'tenant_display_name',
  },
  {
    id: 'address',
    Header: 'Adresse',
    accessor: ({street_name, street_number, postal_code, region}) => {
      return street_name ? (
        <div className={styles.address_container}>
          <span>
            {street_name} {street_number},
          </span>
          <span>
            {postal_code} {region}
          </span>
        </div>
      ) : (
        '-'
      );
    },
    text_styles: 'body2',
    sort: 'region',
  },
  {
    id: 'deposit',
    Header: 'Kaution',
    accessor: 'deposit_amount_cents',
    Cell: ({value}) => (value ? formatPrice(value) : '-'),
    text_styles: 'body1_bright_bold_centered',
    sort: 'deposit_amount_cents',
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({
      status,
      sub_status,
      claims,
      deposit_guarantee_start_date,
      account_balance_cents,
    }) => {
      return (
        <>
          <div
            className={classNames(
              styles.status_circle,
              styles[getStatusColor(sub_status)],
            )}
          />
          <div className={styles.status_text}>
            <p>{status}</p>
            <p
              className={classNames(
                text_styles.caption_left,
                styles.sub_status,
              )}>
              {translateSubStatus({
                sub_status,
                claims,
                deposit_guarantee_start_date,
                account_balance_cents,
              })}
            </p>
          </div>
        </>
      );
    },
    text_styles: 'body2',
    sort: 'deposit_type',
  },
  {
    id: 'created_at',
    Header: 'Erstellt',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
  {
    id: 'actions',
    Header: '',
    accessor: 'actions',
    text_styles: '',
  },
];

function getStatusColor(sub_status) {
  switch (sub_status) {
    case DEPOSIT_SUBSTATUS.released:
    case DEPOSIT_SUBSTATUS.all_claims_paid_out:
    case DEPOSIT_SUBSTATUS.account_balance_empty: {
      return 'status_silver';
    }
    case DEPOSIT_SUBSTATUS.rejected_by_tenant:
    case DEPOSIT_SUBSTATUS.rejected_by_getmomo: {
      return 'status_red';
    }
    case DEPOSIT_SUBSTATUS.video_identification_required:
    case DEPOSIT_SUBSTATUS.invitation_sent:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part: {
      return 'status_orange';
    }
    case DEPOSIT_SUBSTATUS.cash_deposit:
    case DEPOSIT_SUBSTATUS.some_claims_paid_out:
    case DEPOSIT_SUBSTATUS.claim_requested:
    case DEPOSIT_SUBSTATUS.active:
    case DEPOSIT_SUBSTATUS.active_in_future:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full: {
      return 'status_green';
    }
  }
}

async function openTaxSummary({deposit_id}) {
  let tax_summary_url;

  try {
    tax_summary_url = await RPC('getTaxSummaryURL', {
      deposit_id,
      year: TAX_SUMMARY_YEAR,
    });
  } catch (err) {
    return handleError(err);
  }
  openExternal(tax_summary_url);
}
